import React from 'react'
import PropTypes from 'prop-types'
import { graphql } from 'gatsby'

import Layout from '../../components/layout'
import FeatherIcon from '../../components/feather-icon'
import ContactModal from '../../components/contact-modal'
import SVGImage from '../../components/svg-image'

import './main.scss'

const Checkslist = ({ items }) => {
  const listItems = items.map(({ title }, index) => (
    <li className="service-page--list-item" key={`list_item_${index}`}>
      <FeatherIcon name="check" />
      {title}
    </li>
  ))

  return <ul className="list-style--1">{listItems}</ul>
}

const CTAButton = ({ text, successMsg, origin }) => (
  <div className="mt--30">
    <ContactModal
      text={text}
      origin={origin}
      successMsg={successMsg}
      className="rn-button-style--2 btn_border btn-size-sm"
    />
  </div>
)

const ListsPage = ({ data }) => {
  const {
    frontmatter: {
      title,
      description,
      slug,
      promises,
      illustration,
      parent,
      cta,
      seoTitle,
    },
    html,
  } = data.markdownRemark

  let pageOrigin = slug
  pageOrigin = pageOrigin.substring(1)

  return (
    <Layout
      title={title}
      description={description}
      seoTitle={seoTitle}
      breadcrumbParent={parent}
    >
      <div className="container mt--120 mb--120">
        <div className="row align-items-center d-flex">
          <div className="col-lg-6 col-12 mb-5 mb-lg-0">
            <div className="rounded mx-auto d-block pr-lg-3">
              <SVGImage
                svgContent={illustration.fields.svgContent}
                alt={title}
              />
            </div>
          </div>

          <div className="col-lg-6 col-12">
            <div dangerouslySetInnerHTML={{ __html: html }} />

            {cta && (
              <CTAButton
                successMsg={cta.successMsg}
                text={cta.text}
                origin={pageOrigin}
              />
            )}
          </div>
        </div>
      </div>

      <div className="rn-list-style mb--120">
        <div className="container">
          <div className="row">
            {promises &&
              promises.map(({ title, description, caracteristics }, index) => {
                return (
                  <div
                    className="col-lg-4 mb--30 col-12"
                    key={`list_col_${index}`}
                  >
                    <div className="list-style-inner">
                      <h3 className="fontWeight500">{title}</h3>
                      <p>{description}</p>
                      <Checkslist items={caracteristics} />
                    </div>
                  </div>
                )
              })}
          </div>
        </div>
      </div>
    </Layout>
  )
}

export default ListsPage
ListsPage.propTypes = {
  data: PropTypes.shape({
    markdownRemark: PropTypes.shape({
      frontmatter: PropTypes.object,
    }),
  }),
}

export const pageQuery = graphql`
  query ListsPageQuery($id: String!) {
    markdownRemark(id: { eq: $id }) {
      html

      frontmatter {
        title
        description
        slug
        seoTitle

        illustration {
          fields {
            svgContent
          }
        }

        parent {
          title
          url
        }

        cta {
          text
          successMsg
        }

        promises {
          title
          description

          caracteristics {
            title
          }
        }
      }
    }
  }
`
